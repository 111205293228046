
import React from 'react';
import PropTypes from 'prop-types';

const Twitter = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M25 5.02759C23.4274 5.10007 23.4617 5.09416 23.284 5.10904L24.2153 2.44924C24.2153 2.44924 21.3081 3.51869 20.5711 3.71C18.6356 1.9703 15.7585 1.89344 13.7016 3.15763C12.0172 4.19313 11.1132 5.97135 11.4147 8.04979C8.13656 7.59488 5.37453 6.04097 3.19385 3.42141L2.50416 2.59305L1.98879 3.53948C1.33743 4.73539 1.12114 6.10277 1.37959 7.38965C1.48563 7.91761 1.66588 8.42115 1.91555 8.89264L1.32389 8.66338L1.2537 9.65196C1.18256 10.6562 1.51691 11.8284 2.14825 12.7874C2.32601 13.0575 2.55489 13.3541 2.845 13.6467L2.53925 13.5998L2.91233 14.7326C3.40271 16.2209 4.42123 17.3723 5.74303 18.0124C4.42295 18.5726 3.35655 18.9301 1.6037 19.5067L0 20.0338L1.48125 20.8437C2.04601 21.1525 4.04167 22.1838 6.01349 22.4932C10.3966 23.1806 15.3315 22.6208 18.6537 19.6287C21.4519 17.1085 22.3701 13.5237 22.1794 9.7931C22.1506 9.22834 22.3053 8.68932 22.615 8.27562C23.2355 7.44726 24.9962 5.03312 25 5.02759ZM21.4437 7.39824C20.9293 8.08507 20.6716 8.96226 20.7178 9.86787C20.9103 13.6309 19.8864 16.5491 17.6744 18.5412C15.0906 20.8683 10.9228 21.7817 6.24027 21.0472C5.39226 20.9143 4.51584 20.6165 3.79047 20.3192C5.26009 19.8135 6.39496 19.3628 8.22773 18.4954L10.7862 17.2844L7.96146 17.1036C6.60839 17.017 5.48172 16.3612 4.78916 15.2966C5.1569 15.2752 5.51147 15.2157 5.86452 15.1173L8.55865 14.3669L5.84202 13.7016C4.52194 13.3783 3.76949 12.5885 3.37067 11.9827C3.10879 11.5847 2.9377 11.1769 2.83508 10.8009C3.10745 10.874 3.42503 10.9261 3.93715 10.9768L6.45161 11.2255L4.45938 9.67141C3.02391 8.5518 2.44865 6.86971 2.87037 5.25266C7.35035 9.89934 12.6104 9.55011 13.1399 9.67294C13.0234 8.54093 13.0203 8.53826 12.9898 8.43049C12.3117 6.03353 13.7978 4.81645 14.468 4.40446C15.868 3.54406 18.0902 3.41436 19.6295 4.8319C19.9619 5.13784 20.4115 5.25819 20.8322 5.15367C21.2099 5.05983 21.5198 4.96045 21.8233 4.85231L21.1916 6.65628L21.998 6.65685C21.8458 6.86094 21.6633 7.10527 21.4437 7.39824Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Twitter.defaultProps = {
  className: '',
};

Twitter.propTypes = {
  className: PropTypes.string,
};

export default Twitter;
