import React from 'react';
import PropTypes from 'prop-types';

const Mail = ({ className }) => (
  <svg className={className} width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3064 0.611328H2.69375C1.20831 0.611328 0 1.81963 0 3.30489V19.7796C0 21.2649 1.20831 22.4734 2.69375 22.4734H22.3064C23.7917 22.4734 25 21.2649 25 19.7796V3.30489C25 1.81963 23.7917 0.611328 22.3064 0.611328ZM22.3064 2.07617C22.3675 2.07617 22.4274 2.08075 22.4861 2.08952L13.369 11.2066C12.8899 11.6858 12.1101 11.6858 11.631 11.2066L2.51389 2.08952C2.57263 2.08075 2.63252 2.07617 2.69375 2.07617H22.3064ZM23.5352 19.7796C23.5352 20.4573 22.9839 21.0085 22.3064 21.0085H2.69375C2.01607 21.0085 1.46484 20.4573 1.46484 19.7796V3.30508C1.46484 3.24385 1.46942 3.18396 1.478 3.12521L10.5953 12.2423C11.1204 12.7676 11.8101 13.0301 12.5 13.0301C13.1899 13.0301 13.8796 12.7676 14.4047 12.2423L23.522 3.12521C23.5306 3.18396 23.5352 3.24404 23.5352 3.30508V19.7796Z" fill="white" />
  </svg>
);

Mail.defaultProps = {
  className: '',
};

Mail.propTypes = {
  className: PropTypes.string,
};

export default Mail;
