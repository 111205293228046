import React from 'react';
import PropTypes from 'prop-types';

const Mouse = ({ className, innerRef }) => (
  <svg className={className} ref={innerRef} height="512pt" viewBox="-105 0 512 512.00002" width="512pt" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="m150.601562 0c-83.042968 0-150.601562 67.558594-150.601562 150.601562v210.796876c0 83.042968 67.558594 150.601562 150.601562 150.601562 83.042969 0 150.601563-67.558594 150.601563-150.601562v-210.796876c0-83.042968-67.558594-150.601562-150.601563-150.601562zm119.882813 361.398438c0 66.101562-53.78125 119.882812-119.882813 119.882812-66.101562 0-119.882812-53.78125-119.882812-119.882812v-210.796876c0-66.101562 53.78125-119.882812 119.882812-119.882812 66.101563 0 119.882813 53.78125 119.882813 119.882812zm0 0" />
    <path d="m150.605469 101.851562c-8.484375 0-15.359375 6.875-15.359375 15.359376v70.695312c0 8.480469 6.875 15.359375 15.359375 15.359375 8.480469 0 15.359375-6.878906 15.359375-15.359375v-70.695312c0-8.484376-6.875-15.359376-15.359375-15.359376zm0 0" />
  </svg>
);

Mouse.defaultProps = {
  className: '',
};

Mouse.propTypes = {
  className: PropTypes.string,
};

export default React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Mouse innerRef={ref} {...props} />
));
