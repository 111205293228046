import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M14.1878 24.9512H10.1764C9.50642 24.9512 8.96138 24.4072 8.96138 23.7385V14.7083H6.62107C5.95105 14.7083 5.40601 14.1642 5.40601 13.4956V9.6262C5.40601 8.95748 5.95105 8.41351 6.62107 8.41351H8.96138V6.47583C8.96138 4.55456 9.56586 2.91996 10.7093 1.74904C11.8578 0.572777 13.4629 -0.0488281 15.3511 -0.0488281L18.4103 -0.043869C19.0792 -0.0427246 19.6233 0.501251 19.6233 1.16882V4.76151C19.6233 5.43022 19.0785 5.9742 18.4086 5.9742L16.3489 5.97496C15.7207 5.97496 15.5607 6.10066 15.5265 6.13918C15.4701 6.20308 15.4031 6.38371 15.4031 6.88248V8.41332H18.2538C18.4684 8.41332 18.6764 8.46615 18.855 8.56571C19.2405 8.78067 19.4802 9.18713 19.4802 9.62639L19.4786 13.4958C19.4786 14.1642 18.9336 14.7081 18.2636 14.7081H15.4031V23.7385C15.4031 24.4072 14.8578 24.9512 14.1878 24.9512ZM10.4299 23.4856H13.9344V14.0522C13.9344 13.6057 14.2985 13.2425 14.7457 13.2425H18.0102L18.0115 9.87911H14.7455C14.2983 9.87911 13.9344 9.51595 13.9344 9.06944V6.88248C13.9344 6.30989 13.9927 5.65872 14.4257 5.1693C14.949 4.57764 15.7736 4.50935 16.3485 4.50935L18.1548 4.50859V1.42136L15.3499 1.41678C12.3155 1.41678 10.4299 3.35541 10.4299 6.47583V9.06944C10.4299 9.51576 10.066 9.87911 9.61879 9.87911H6.87448V13.2425H9.61879C10.066 13.2425 10.4299 13.6057 10.4299 14.0522V23.4856ZM18.4075 1.42174H18.4077H18.4075Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Facebook.defaultProps = {
  className: '',
};

Facebook.propTypes = {
  className: PropTypes.string,
};

export default Facebook;
