import React from 'react';
import PropTypes from 'prop-types';

const Spotify = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M21.3388 3.66116C18.9779 1.30024 15.8388 0 12.5 0C9.16119 0 6.02207 1.30024 3.66116 3.66116C1.30024 6.02207 0 9.161 0 12.5C0 15.8388 1.30024 18.9779 3.66116 21.3388C6.02226 23.6998 9.16119 25 12.5 25C15.8388 25 18.9779 23.6998 21.3388 21.3388C23.6998 18.9779 25 15.8388 25 12.5C25 9.161 23.6998 6.02207 21.3388 3.66116ZM12.5 23.5352C6.41518 23.5352 1.46484 18.5848 1.46484 12.5C1.46484 6.41518 6.41518 1.46484 12.5 1.46484C18.5848 1.46484 23.5352 6.41518 23.5352 12.5C23.5352 18.5848 18.5848 23.5352 12.5 23.5352Z" fill="white" />
      <path d="M20.9509 9.78108C17.5567 8.25749 13.9347 7.48502 10.1852 7.48502C8.32097 7.48502 6.4594 7.68109 4.652 8.0679C4.25641 8.1524 4.00445 8.54169 4.08914 8.93727C4.17363 9.33286 4.56292 9.5852 4.95851 9.50032C6.66539 9.13506 8.42378 8.94986 10.1852 8.94986C13.7266 8.94986 17.1467 9.67923 20.3508 11.1176C20.4483 11.1612 20.5501 11.182 20.6505 11.182C20.9301 11.182 21.1971 11.0209 21.319 10.7493C21.4847 10.3802 21.3197 9.94683 20.9509 9.78108Z" fill="white" />
      <path d="M17.9101 16.6817C15.4759 15.5815 12.8769 15.0236 10.1851 15.0236C8.75268 15.0236 7.32503 15.1857 5.94182 15.5052C5.54777 15.5962 5.3021 15.9895 5.39308 16.3836C5.48406 16.7778 5.87736 17.0235 6.2716 16.9325C7.54686 16.6378 8.8635 16.4885 10.1851 16.4885C12.6675 16.4885 15.0635 17.0025 17.3068 18.0164C17.4046 18.0609 17.507 18.0817 17.6079 18.0817C17.8868 18.0817 18.1534 17.9215 18.2757 17.6508C18.4424 17.2823 18.2787 16.8484 17.9101 16.6817Z" fill="white" />
      <path d="M19.4545 13.1765C16.5329 11.8612 13.4142 11.1944 10.185 11.1944C8.53324 11.1944 6.8851 11.3735 5.28636 11.7268C4.89135 11.8141 4.64187 12.2051 4.72923 12.6C4.81658 12.995 5.20759 13.2448 5.60241 13.1571C7.09758 12.8267 8.63929 12.6593 10.185 12.6593C13.2053 12.6593 16.1218 13.2826 18.8531 14.5123C18.9508 14.5561 19.0528 14.5769 19.1534 14.5769C19.4328 14.5769 19.6996 14.4163 19.8217 14.1449C19.9878 13.7762 19.8234 13.3427 19.4545 13.1765Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Spotify.defaultProps = {
  className: '',
};

Spotify.propTypes = {
  className: PropTypes.string,
};

export default Spotify;
